import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';

import './style.scss';

const Page = () => {
    return (
        <Layout>
            <Seo title="404 - Page Not Found" />
            <div className="not-found-page">
                <Container className="container-1">
                    <Row>
                        <Col>
                            <h1>404 - Page Not Found</h1>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default Page;
